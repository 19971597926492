import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea} from '@mui/material';


export default function MultiActionAreaCard(props) {
  return (
    <Card sx={{ maxWidth: 300 ,width:300}}>
      <CardActionArea>
        <CardMedia
          component="img"
          height={props.height}
          image={props.image}
          alt={props.alt}
        />
        <CardContent sx={{backgroundColor:'white'}}>
          <Typography className='text' gutterBottom  variant="h6" component="div" sx={{textAlign:'center',color:'black'}}>
          <p >{props.name} :    {props.index} </p>  
          </Typography>
          <Typography className='text' gutterBottom  variant="h6" component="div" sx={{textAlign:'center',color:'black'}}>
          <p >Points: {props.Score} </p> 
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

