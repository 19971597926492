import React, { useState, useEffect } from "react";
import "../Auth/authForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTv, faCalendarDays, faLink } from "@fortawesome/free-solid-svg-icons";
import { addAnimeCharacterAPI, returnAnimeListAPI } from "../../helper/helper";



const linkIcon = <FontAwesomeIcon icon={faLink} />;
const tvIcon = <FontAwesomeIcon icon={faTv} beat />;
const yearIcon = <FontAwesomeIcon icon={faCalendarDays} />;

const CharacterAdd = () => {
  
  const [animeList, setAnimeList] = useState([]);
  const [isAdded, setIsAdded] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchAnimeList = async () => {
      try {
        const response = await returnAnimeListAPI();
        setAnimeList(response.data);
      } catch (err) {
        console.error("Error fetching anime list:", err);
      }
    };

    fetchAnimeList();
  }, []);

  const animeDropdownOptions = animeList.map((anime) => (
    <option key={anime._id} value={anime._id}>
      {anime.title}
    </option>
  ));

  const handleSubmit = async (event) => {
    event.preventDefault();

    const name = event.target.name.value;
    const anime = event.target.anime.value;
    const pictureLink = event.target.pictureLink.value;

    try {
      await addAnimeCharacterAPI(name, anime, pictureLink);

      if (localStorage.getItem("status")) {
        setIsAdded("Character Successfully Added");
        setTimeout(() => {
          setIsAdded("");
        }, 2000); // Hide the message after 5 seconds
      } else {
        setError("Failed to add, check shit code");
      }
    } catch (error) {}

    event.target.reset();
  };

  return (
    <div className="Admin-box">
      <div className="login-box">
        <form onSubmit={handleSubmit} method="POST">
          <h2>New Character</h2>
          {isAdded && <p className="error">{isAdded}</p>}
          {error && <p className="error">{error}</p>}
          <div className="input-box">
            <span className="icon">{tvIcon}</span>
            <input type="text" id="name" name="name" required />
            <label htmlFor="name">Name</label>
          </div>
          <div className="input-box">
            <span className="icon">{yearIcon}</span>
            <select name="anime" id="anime" required>
              <option value="" disabled selected>
                Select an anime
              </option>
              {animeDropdownOptions}
            </select>
          </div>
          <div className="input-box">
            <span className="icon">{linkIcon}</span>
            <input type="text" id="pictureLink" name="pictureLink" required />
            <label htmlFor="pictureLink">Picture Link</label>
          </div>
          <div className="register-link">
            <button type="submit" value="submit">
              ADD
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CharacterAdd;

// import React, {useState, useEffect} from "react";
// import "../Auth/auth.css"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {faTv,faCalendarDays,faLink} from '@fortawesome/free-solid-svg-icons'
// import { addAnimeCharacterAPI, returnAnimeListAPI } from "../../helper/helper";
// import { useNavigate } from "react-router-dom";
// import { isAxiosError } from "axios";

// const linkIcon = <FontAwesomeIcon icon={faLink} />
// const tvIcon = <FontAwesomeIcon icon={faTv} beat />
// const yearIcon = <FontAwesomeIcon icon={faCalendarDays} />



// const CharacterAdd = () => {
//     const navigate=useNavigate();
//     const [animeList, setAnimeList] = useState([]);
//     const [isAdded, setIsAdded] = useState("");
//     const [error, setError] = useState("");
    

//     useEffect(() => {
//       const fetchAnimeList = async () => {
//         try {
//           const response = await returnAnimeListAPI();
//           // console.log("Response:", response);
//           setAnimeList(response.data);
    
//         } catch (err) {
//           console.error('Error fetching anime list:', err);
//         }
//       };
  
//       fetchAnimeList();
//     }, []);
   
//     const animeDropdownOptions = animeList.map((anime) => (
//       <option key={anime._id} value={anime._id}>
//         {anime.title}
//       </option>
//     ));
  
//     const handleSubmit = async (event) => {
//       event.preventDefault();
  
//       const name = event.target.name.value;
//       const anime = event.target.anime.value;
//       const pictureLink = event.target.pictureLink.value;
  
//       // Do something with the form data
//       try{
//         await addAnimeCharacterAPI(name,anime,pictureLink);

//         if (localStorage.getItem('status')){
//           setIsAdded("Character Successfully Added")
//         }else{
//           setError("Failed to add, check shit code")
//         }
        
//       }catch(error){
      
//       }
//       // Reset the form
//       event.target.reset();
//       // if (isAdded) {
//       //   navigate("/animeAdded")
//       // }


//     };
  
//     return (
//       <div className='Admin-box'>
//         <div className='login-box'>
//           <form onSubmit={handleSubmit} method='POST'>
//             <h2>New Character</h2>
//             {isAdded && <p className="error-message">{isAdded}</p>}
//             <div className='input-box'>
//               <span className='icon'>{tvIcon}</span>
//               <input type='text' id='name' name='name' required />
//               <label htmlFor='name'>Name</label>
//             </div>
//             <div className='input-box'>
//               <span className='icon'>{yearIcon}</span>
//               <select name='anime' id='anime' required>
//                 <option value='' disabled selected>
//                   Select an anime
//                 </option>
//                 {animeDropdownOptions}
//               </select>
//             </div>
//             <div className='input-box'>
//               <span className='icon'>{linkIcon}</span>
//               <input type='text' id='pictureLink' name='pictureLink' required />
//               <label htmlFor='pictureLink'>Picture Link</label>
//             </div>
//             <div className='register-link'>
//               <button type='submit' value='submit'>ADD</button>
//             </div>
//           </form>
//         </div>
//       </div>
//     );
// }

// export default CharacterAdd