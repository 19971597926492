import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea} from '@mui/material';


export default function MultiActionAreaCard(props) {
  return (
    <Card sx={{ maxWidth: 300}}>
      <CardActionArea>
        <CardMedia
          component="img"
          height={props.height}
          image={props.image}
          alt={props.alt}
        />
        <CardContent  sx={{backgroundColor:'whitesmoke', height:'6rem',display:'flex',justifyContent:"center",alignItems:'center',alignContent:'center'}}>
          <Typography  gutterBottom variant="h5" component="div" sx={{textAlign:'center',color:'black'}}>
          <p>{props.name}</p>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

