import React, { useState, useEffect } from "react";
import { returnCharacterListAPI } from "../../helper/helper";
import MultiActionAreaCard from "./card";
import { Grid, TextField, Button } from "@mui/material";
import { addRankScoresAPI, addRankScoresAPIFav } from "../../helper/helper";
import "./rankingLogicTable.css"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPersonChalkboard, faUser } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const instruction = <FontAwesomeIcon icon={faPersonChalkboard} />


function RankingLogic() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [characterList, setCharacterList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [assignedNumbers, setAssignedNumbers] = useState({});
  const [selectedCharacters, setSelectedCharacters] = useState([]);
  const navigate = useNavigate();

  //REPONSIVE DIALOG
  const [open, setOpen] = React.useState(false);
  const [openIntructions, setOpenIntructions] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenIntructions = () => {
    setOpenIntructions(true);
  };
  const handleCloseIntructions = () => {
    setOpenIntructions(false);

  };


  const handleClose = (submit) => {
    setOpen(false);

    if (submit) {
      handleFormSubmit();
    }
  };


  useEffect(() => {
    const fetchCharacterList = async () => {
      try {
        const response = await returnCharacterListAPI(localStorage.getItem("animeId"));
        setCharacterList(response.data);
      } catch (err) {
        console.error("Error fetching character list:", err);
      }
    };

    fetchCharacterList();
  }, []);

  const assignNumber = (characterId) => {
    const numbers = Object.values(assignedNumbers);
    let newNumber = 1;
    while (numbers.includes(newNumber) && newNumber <= 30) {
      newNumber++;
    }
    if (newNumber <= 30) {
      const updatedNumbers = { ...assignedNumbers, [characterId]: newNumber };
      setAssignedNumbers(updatedNumbers);
      return newNumber;
    } else {
      return null;
    }
  };

  const removeNumber = (characterId) => {
    const { [characterId]: removed, ...updatedNumbers } = assignedNumbers;
    setAssignedNumbers(updatedNumbers);
  };

  const toggleCharacterSelection = (characterId) => {
    const characterIndex = selectedCharacters.findIndex((character) => character.characterId === characterId);

    if (characterIndex === -1) {
      // Character not selected, add to selectedCharacters
      const character = characterList.find((character) => character._id === characterId);
      const newCharacter = { characterId, number: assignNumber(characterId), name: character.name };
      setSelectedCharacters([...selectedCharacters, newCharacter]);
    } else {
      // Character already selected, remove from selectedCharacters
      const updatedCharacters = [...selectedCharacters];
      updatedCharacters.splice(characterIndex, 1);
      setSelectedCharacters(updatedCharacters);
      removeNumber(characterId);
    }
  };



  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFormSubmit = async () => {
    const charactersWithNumbers = selectedCharacters.map((character) => ({
      animeCharacter: character.characterId,
      rankScore: 31 - character.number,
      anime: localStorage.getItem("animeId"),
      user: user._id,
    }));

    try {
      if (localStorage.getItem('power')) {
        await addRankScoresAPI(charactersWithNumbers);
      } else if (localStorage.getItem('fav')) {
        await addRankScoresAPIFav(charactersWithNumbers)
      }


      // If the API call was successful
      toast.success("Your vote was submitted");
      setSelectedCharacters([]);
      setAssignedNumbers({});
 
     
      // Render "Your vote was submitted" in the UI or perform any other desired actions
    } catch (error) {
      if (error.response.status===400){
        toast.error("Wait till tomorrow to submit again");
      }else{toast.error("Something went wrong, please try again later");}
    }

  };

  const filteredCharacterList = characterList.filter((character) =>
    character.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (

    <Grid container padding={5}  spacing={2} direction='column' >

    <Grid container sx={{justifyContent:'center', alignItems:'center', display:'flex', paddingLeft:'16px'}}>
    
    <ToastContainer />
          <Grid item xs={8}>
            <Button variant="outlined" onClick={handleClickOpen}>
            Submit
          </Button>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"SUBMISSION RULES"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Submission is only allowed once per day per poll. Please make sure you are absolutely certain before procedding.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => handleClose(false)}>
                GO BACK
              </Button>
              <Button onClick={() => handleClose(true)} autoFocus>
                SUBMIT
            </Button>
            </DialogActions>
          </Dialog>
          </Grid>
    
          <Grid item xs={4} sx={{ display:"inline-block"}}> 
          <div onClick={handleClickOpenIntructions} style={{color:'black',cursor: 'pointer' }}>Intructions {instruction}</div>
          <Dialog
            fullScreen={fullScreen}
            open={openIntructions}
            onClose={handleCloseIntructions}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"INSTRUCTIONS"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <b>Adding to Rank-</b>Clicking on a character card adds them to the rank table displayed on the left. The ranking starts from number 1 and ends at 30.
                <b> Removing Character-</b>You can remove a character from the rank table by clicking on the card again. 
                <b> Submission Limitation-</b> You are free to submit any amount of characters between 1-30.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => handleCloseIntructions(false)}>
                GIVE IT A TRY
              </Button>
            </DialogActions>
          </Dialog>
          </Grid>
    
    </Grid>
    
          <Grid item xs={12}>
            <TextField
              label="Search Character"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              fullWidth
            />
          </Grid>
     
          <Grid container item   >
    
          <Grid item xs={6} md={4} lg={2}  marginRight={0}  className="custom-table" >
          

        
            <table className="table">
              <thead className="table-header">
                <tr className="table-row filter__link">
                  <th >Name</th>
                  <th >Rank</th>
                </tr>
              </thead>
              <tbody >
                {selectedCharacters.map((character) => (
                  <tr  className="table-data" key={character.characterId}>
                    <td >{character.name}</td>
                    <td >{character.number}</td>
                  </tr>
                ))}
              </tbody>
            </table>
   
          </Grid>
    
          <Grid container xs={6} md={8} lg={10}  spacing={0.2} >
    
          {filteredCharacterList.map((character) => (
            <Grid item  xs={12} md={4} lg={3}  key={character._id}   >
              <MultiActionAreaCard
                component="img"
                height="120"
                image={character.pictureLink}
                alt={character.name + " image"}
                name={character.name}
                characterId={character._id}
                onAssignNumber={toggleCharacterSelection}
                onRemoveNumber={toggleCharacterSelection}
    
              />
            </Grid>
          ))}
    
          </Grid>
    
          </Grid>
    
        </Grid>
  );
}

export default RankingLogic;

