
import React, {  useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import AdminPage from "../AdminPage";


export default function UserDetails() {
    const navigate= useNavigate();
    const [admin, setAdmin] = useState(false);
    const [verify, setUserData] = useState([]);
   
    useEffect(() => {
      const fetchData = async () => {
        try {
          // const token = localStorage.getItem("token");
          
          const user = JSON.parse(localStorage.getItem("user"));

          if(user.role===0){
            setAdmin(true);
            
          }
  
          setUserData('answer'); //for some reason taht i can't figure out, i need this line to render the return statement
       
        } catch (error) {
          console.error(error);
          // Handle error appropriately
        }
      };
  
      fetchData();
    }, []);
  
    return admin ? <AdminPage admin={admin}/> : navigate("/") ;
  };
  