import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import MultiActionAreaCard from "./card";
import { returnRankListAPI } from "../../helper/helper";


function Poll() {
  const [rankList, setRankList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");


  useEffect(() => {
    const fetchRankList = async () => {
      try {
        const response = await returnRankListAPI(localStorage.getItem("animeId"));
        setRankList(response.data);
        // console.log(response.data)
      } catch (err) {
        console.error("Error fetching character list:", err);
      }
    };

    fetchRankList();
  }, []);

  const sortedRankList = [...rankList].sort((a, b) => b.totalRankScore - a.totalRankScore);
  let counter=1
  const sortedRankListWithIndex=sortedRankList.map(item=>({...item,key:counter++}));

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredCharacterList = sortedRankListWithIndex.filter((rank) =>
    rank.animeCharacter.name.toLowerCase().includes(searchQuery.toLowerCase())
  );



  return (
    <Grid container padding={2} spacing={3}>


      <Grid item xs={12} >
        <TextField
          label="Search Character"
          value={searchQuery}
          onChange={handleSearchChange}
          fullWidth
          margin="normal"
          InputProps={{
            style: { backgroundColor: 'rgba(255,255,255, 0.5)', color: 'black' },
          }}
          InputLabelProps={{
            style: { fontWeight: 'bold' },
            TypographyProps: { fontWeight: 'bold' },
          }}
        />
      </Grid>





      {filteredCharacterList.map((rank) => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={rank._id} sx={{ justifyContent: "center", display: "flex" }}>
          <MultiActionAreaCard
            component="img"
            height="200"
            image={rank.animeCharacter.pictureLink}
            characterId={rank.animeCharacter._id}
            name={rank.animeCharacter.name}
            alt={rank.animeCharacter.name + "image"}
            Score={rank.totalRankScore}
            index={rank.key}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default Poll