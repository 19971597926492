import React from 'react'
import { Grid } from '@mui/material';
import AnimeAdd from './Add/AnimeAdd';
import CharacterAdd from './Add/CharacterAdd';
import { Navigate } from 'react-router-dom';
import Header from './Header/Header';

const AdminPage = ({ admin }) => {

  if (!admin) {
    return <Navigate to="/" />;
  }

  return (
    <section>
      <head> <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1004746759020177"
        crossorigin="anonymous"></script></head>
      <Header />
      <div className='flexCenter paddings innerWidth'>
        <Grid container padding={5} spacing={1} >

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <CharacterAdd />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <AnimeAdd />
          </Grid>

        </Grid>
      </div>
    </section>







  )
}

export default AdminPage