import React from 'react'
import Header from './Header/Header';
import RankingLogic from './Ranking/RankingLogic';

function RankingPage() {
  return (
    <section>
      <head> <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1004746759020177"
        crossorigin="anonymous"></script></head>
      <Header />
      <div className='flexCenter paddings innerWidth'>
        <RankingLogic />
      </div>
    </section>




  )


}

export default RankingPage