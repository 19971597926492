import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faUser, faFlag } from '@fortawesome/free-solid-svg-icons';
import { RegisterAPI } from '../../helper/helper';
import countryList from 'react-select-country-list';
import { LoginAPI } from '../../helper/helper';
import Header from '../Header/Header';
import 'react-toastify/dist/ReactToastify.css';
import './authForm.css';


const flagIcon = <FontAwesomeIcon icon={faFlag} />;
const emailIcon = <FontAwesomeIcon icon={faEnvelope} />;
const passwordIcon = <FontAwesomeIcon icon={faLock} />;
const userIcon = <FontAwesomeIcon icon={faUser} />;

function Register() {
    const [isRegistered, setIsRegistered] = useState(false);
  

    // GET OPTIONS FOM COUNTRY LIST
    const options = countryList().getData();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const username = event.target.username.value.toLowerCase();
        const password = event.target.password.value;
        const email = event.target.email.value;
        const country = event.target.country.value;

        // Validate input data
        const validationErrors = {};
        if (!username || !password || !email || !country) {
            validationErrors.general = 'All fields are required.';
        }

        if (password.length < 5) {
            toast.error('Password must be at least 5 characters long.');
            validationErrors.password = 'Password must be at least 5 characters long.';
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            validationErrors.email = 'Invalid email address.';
            toast.error('Invalid email address.');
        }

       

        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        try {
            const response = await RegisterAPI(username, password, email, country);
            console.log(response);
            if (response.status === 201) {
                await LoginAPI(username, password);
                setIsRegistered(true)
            }else if(response.response.status===409){
                toast.error('Username or email already exists');
            }
            
        } catch (error) {
                toast.error('Something went wrong.');
    
        }
    };

    // Render success screen if registration is successful
    if (isRegistered) {
        return (
            <div className="login-body">
                <div className="login-box">
                    <h2>Welcome Fellow Weeb!</h2>
                    <Link to="/">Get Started</Link>
                </div>
            </div>
        );
    }

    // REGISTER FORM
    return (
        <section>
            <head>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1004746759020177"
                    crossorigin="anonymous"></script>

            </head>
            <Header/>
            <div className='flexCenter paddings innerWidth'>


                <div className="login-body">
                    <ToastContainer />
                    <div className="login-box">
                        <form onSubmit={handleSubmit}>
                            <h2>Register</h2>                       
                            <div className="input-box">
                                <span className="icon">{userIcon}</span>
                                <input type="text" id="username" name="username" required />
                                <label htmlFor="username">Username</label>

                            </div>
                            <div className="input-box">
                                <span className="icon">{passwordIcon}</span>
                                <input type="password" id="password" name="password" required />
                                <label htmlFor="password">Password</label>

                            </div>
                            <div className="input-box">
                                <span className="icon">{emailIcon}</span>
                                <input type="email" id="email" name="email" required />
                                <label htmlFor="email">Email</label>

                            </div>
                            <div className="input-box">
                                <span className="icon">{flagIcon}</span>
                                <select id="country" name="country" required>
                                    <option value="">Select Country</option>
                                    {options.map((country) => (
                                        <option key={country.code} value={country.label}>
                                            {country.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button type="submit">Sign-Up</button>
                            <div className="register-link">
                                <p>
                                    Already have an account?
                                    <Link to="/login">Login</Link>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
        </section>



    );
}

export default Register;


