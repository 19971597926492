import React from 'react'
import Header from '../Header/Header'
import "./style.css"

const About = () => {
  return (
    <section>
      <head> <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1004746759020177"
        crossorigin="anonymous"></script></head>
      <Header />
      <div className='aboutbody'>

        <div className='about-text'>
          <h1>OUR PURPOSE</h1>
          <p> Welcome to AnimePoll, the ultimate platform for anime enthusiasts to actively rank their favorite characters from various anime series! Here at AnimePoll, we believe that every fan's choice deserves to be shown, and that's why we've created a space where users like you can contribute to the most comprehensive and dynamic anime character ranking system.</p>
          <p><b style={{color:'gold'}}>Our Mission:</b>
            AnimePoll was born out of a passion for anime and a desire to create a community-driven platform where fans can come together to rank and discuss their favorite characters. Our mission is to provide a fun, interactive, and inclusive space that empowers fans to actively participate in ranking the anime characters they adore.</p>
          <p><b style={{color:'gold'}}>How It Works:</b>
            Ranking your favorite anime characters on AnimePoll is a breeze. As a registered user, you can easily create your personal anime character list and rearrange them based on your preferences. Just search for your beloved characters from a vast database of anime series. Feel free to modify your rankings everyday if you want, reflecting the ever-changing nature of your affection for these captivating characters.</p>
          <p><b style={{color:'gold'}}>Contribute to the Community:</b>
            What sets AnimePoll apart is its collaborative approach to ranking. Every contribution from our users counts! By participating actively in our ranking system, you not only express your love for your favorite characters but also contribute to the overall aggregated results. Our system tallies all user rankings to generate a constantly updated, real-time ranking of the most beloved anime characters across the platform.</p>

        </div>


      </div>

    </section>
  )
}

export default About