import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from './Header/Header';
import { FavPoll } from './InternationalPoll/FavPoll';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPersonChalkboard} from '@fortawesome/free-solid-svg-icons'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import "../components/Header/header.css"

const instruction = <FontAwesomeIcon icon={faPersonChalkboard} />

const FavouritePoll = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    setUser(storedUser);
  }, []);

  const handleSubmit = () => {
    if (user) {
      navigate('/rank/${animeId}');
    } else {
      toast.error('Please sign in to contribute to the International Poll.');
    }
  };

    //REPONSIVE DIALOG
  const [openIntructions, setOpenIntructions] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpenIntructions = () => {
    setOpenIntructions(true);
  };
  const handleCloseIntructions = () => {
    setOpenIntructions(false);

  };

  return (
    <section>
      <head> <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1004746759020177"
        crossorigin="anonymous"></script></head>
      <Header />
      <div className='flexCenter paddings innerWidth'>
      <ToastContainer />
        <Grid container>
          <Grid item xs={12}  >
            <Button variant="contained" color="primary" onClick={handleSubmit} style={{ fontFamily: 'Share Tech Mono' }}>
              ADD YOUR VOTE
            </Button>
          </Grid>
{/* NO RAGING BOX */}
          <Grid item  sx={{ display:"inline-block" ,paddingTop:"1rem"}}> 
          <div onClick={handleClickOpenIntructions}  style={{color:'black',cursor: 'pointer' }} className='rage'>READ BEFORE YOU RAGE {instruction}</div>
          <svg className='logo-fire'>
          <filter id="fire">
            <feTurbulence id="turbulance" baseFrequency="0.1 0.1" numOctaves="2" seed="3">
              <animate attributeName='baseFrequency' dur="10s"
                values='0.1 0.1;0.12 0.2' repeatCount='indefinite'></animate>
            </feTurbulence>
            <feDisplacementMap in="SourceGraphic" scale="3"></feDisplacementMap>
          </filter>
        </svg>
          <Dialog
            fullScreen={fullScreen}
            open={openIntructions}
            onClose={handleCloseIntructions}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"INFO"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <b> What we are trying to achieve-</b>The pupose of these polls is to reflect the anime community's aggregated view on characters.The polls reflect the general view of what the community thinks. 
                <b>Not seeing a character?-</b> It is very easy to miss a character, we do apologize. Please contact us at animepollrank@gmail.com so we can add them.
                <b>One Piece-</b>Dead or assumed dead characters are excluded from the list.

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => handleCloseIntructions(false)}>
                I UNDERSTAND
              </Button>
            </DialogActions>
          </Dialog>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: '20px', marginBottom: '0' }}>
            <Typography variant="h5" align="center" style={{ fontSize: '1.5rem', color: 'black', fontFamily: 'Share Tech Mono' }}>
              MOST LOVED CHARACTHERS
            </Typography>
          </Grid>

          <Grid container>
            <FavPoll />
          </Grid>

        </Grid>

      </div>
    </section>


  );
}

export default FavouritePoll