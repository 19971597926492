import React, { useState, useEffect } from 'react';
import { Typography, Button } from '@mui/material';
import { Link} from 'react-router-dom';
import { BiMenuAltRight } from "react-icons/bi"
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useRef } from "react";
import logo from "../../img/logo.png"
import "./header.css"



const userIcon = <FontAwesomeIcon icon={faUser} />;

const Header = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const navRef = useRef();
  const navigate = useNavigate();

 
  const showNavbar = () => {
    navRef.current.classList.toggle(
      "responsive_nav"
    );
  };

  const handleLogout = () => {
    localStorage.clear();
    setUser(null); // Set user state to null after logout
    navigate("/");
  };

  const handleSignIn = () => {
    navigate('/login');
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);


  return (
    <section className='h-wrapper'>
      <header className='flexCenter paddings innerWidth h-container'>
        {/* <Typography className='logo-css' component={Link} to="/" variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
          ANIME POLL
        </Typography> */}
       <Link to='/'>  <img src={logo} alt='logo'  width={100}  /> </Link> 

        {/* <svg className='logo-fire'>
          <filter id="fire">
            <feTurbulence id="turbulance" baseFrequency="0.1 0.1" numOctaves="2" seed="3">
              <animate attributeName='baseFrequency' dur="10s"
                values='0.1 0.1;0.12 0.2' repeatCount='indefinite'></animate>
            </feTurbulence>
            <feDisplacementMap in="SourceGraphic" scale="3"></feDisplacementMap>
          </filter>
        </svg> */}
        <nav ref={navRef} >
        <div className='links'>
        <Link to='/' >Home</Link>
          <Link to='/about'>About Us</Link>
          {/* <Link to='/contact'>Contact</Link> */}
        </div>
         
      
          <button
            className=" nav-close-btn"
            onClick={showNavbar}>
            <BiMenuAltRight size={30} />
          </button>
        </nav>
      
        {user ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            
              <span className='log-in' onClick={handleLogout} >Logout {userIcon}</span>
              <Typography  variant="h6" color="inherit" sx={{ mx: 1 }} style={{ fontFamily: 'Share Tech Mono' }}>
                <div className='username'>{user.username}</div>
              </Typography>
            </div>
          ) : (

            <Link to='/login' className='log-in'  onClick={handleSignIn} >Sign In {userIcon} </Link>

          )}
  
        <button
          className="nav-btn"
          onClick={showNavbar}>
          <BiMenuAltRight size={30} />
        </button>
      </header>
    </section>



  );
}

export default Header