import PollType from "./components/AnimeSelect/PollType";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Home from "./components/Home";
import InternationalPoll from "./components/PowerInternationalPoll";
import FavouritePoll from "./components/FavouritePoll";
import RankingPage from "./components/RankingPage";
import About from "./components/Donations/About";
// import Contact from "./components/Donations/Contact";
import { BrowserRouter, Routes, Route ,} from "react-router-dom";


function App() {
  return (
    <BrowserRouter>
      
        <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<Register />} />
          <Route path="/Poll/:animeId" element={<PollType/>} />
          <Route path="/Poll/powerranking/:animeId" element={<InternationalPoll/>} />
          <Route path="/Poll/favourite/:animeId" element={<FavouritePoll/>} />
          <Route path="/rank/:animeId" element={<RankingPage/>} />
          <Route path="/about" element={<About/>} />
          {/* <Route path="/contact" element={<Contact/>} /> */}
        </Routes>
    </BrowserRouter>
  );
}

export default App;
