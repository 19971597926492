import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MultiActionAreaCard from "./card";
import { Grid, TextField } from '@mui/material';
import { returnAnimeListAPI } from "../../helper/helper";
import Header from "../Header/Header";


const PollType = () => {
  const [animeList, setAnimeList] = useState([]);
  const [anime, setAnime] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAnimeList = async () => {
      try {
        const response = await returnAnimeListAPI();
        setAnimeList(response.data);
      } catch (err) {
        console.error('Error fetching anime list:', err);
      }
    };

    fetchAnimeList();
  }, []);

  useEffect(() => {
    const id = localStorage.getItem('animeId');
    const item = animeList.find((obj) => obj['_id'] === id);
    setAnime(item);
  }, [animeList]);


  const handleCardClickF = (animeId) => {
    if (localStorage.getItem('power')) {
      localStorage.removeItem('power');
    }
    localStorage.setItem('fav', 'fav')
    navigate(`/Poll/favourite/${animeId}`);

  };
  const handleCardClickP = (animeId) => {
    if (localStorage.getItem('fav')) {
      localStorage.removeItem('fav');
    }
    localStorage.setItem('power', 'power')
    navigate(`/Poll/powerranking/${animeId}`);

  };



  return (
    <section>
      <head> <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1004746759020177"
        crossorigin="anonymous"></script></head>
      <Header />
      <div className='flexCenter paddings innerWidth'>
        <Grid container padding={5} spacing={2}>
        
          {anime && (
            <Grid item xs={12}>
              <div style={{ color: 'black', justifyContent: 'center', display: 'flex', alignItems: 'center', fontSize: '2em', marginBottom: '40px' }}>
                {anime.title + " Polls"}
              </div>
            </Grid>
          )}

          <Grid item xs={12} md={6} lg={6} sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
            <div onClick={() => handleCardClickF(anime._id)}>
              <MultiActionAreaCard
                component="img"
                height="150"
                image={"https://www.phdmedia.com/honduras/wp-content/uploads/sites/93/2019/01/Social-media-likes-e1548412432379.jpg"}
                alt={anime && anime.title + " image"}
                name={"FAVOURITE"}
              />
            </div>
          </Grid>

          <Grid item xs={12} md={6} lg={6} sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
            <div onClick={() => handleCardClickP(anime._id)}>
              <MultiActionAreaCard
                component="img"
                height="150"
                image={"https://www.nicepng.com/png/detail/235-2359549_street-fighter-ko-png-ko-street-fighter-png.png"}
                alt={anime && anime.title + " image"}
                name={"POWER RANKING"}
              />
            </div>
          </Grid>

        </Grid>
      </div>
    </section>









  )
}

export default PollType