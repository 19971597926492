import React, {useState} from "react";
import "../Auth/authForm.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTv,faCalendarDays,faLink} from '@fortawesome/free-solid-svg-icons'
import { addAnimeAPI } from "../../helper/helper";


const linkIcon = <FontAwesomeIcon icon={faLink} />
const tvIcon = <FontAwesomeIcon icon={faTv} beat />
const yearIcon = <FontAwesomeIcon icon={faCalendarDays} />


const AnimeAdd = () => {
    
    const [error, setError] = useState("");
    const [isAdded, setIsAdded] = useState("");
    

    const handleSubmit = async (event) => {
      event.preventDefault();
  
      const title = event.target.title.value;
      const releaseYear = event.target.releaseYear.value;
      const pictureLink = event.target.pictureLink.value;
  
      try {
        await addAnimeAPI(title, releaseYear, pictureLink);
        if (localStorage.getItem("status")) {
          setIsAdded("Anime Successfully Added");
          setTimeout(() => {
            setIsAdded("");
          }, 2000); // Hide the message after 5 seconds
        } else {
          setError("Failed to add, check shit code");
        }
      } catch (error) {
        setError(error);
      }

      event.target.reset();
    };
  
   
  
    return (
      <div className='Admin-box'>
        <div className='login-box'>
          <form onSubmit={handleSubmit} method='POST'>
            <h2>New Anime</h2>
            {isAdded && <p className="error">{isAdded}</p>}
            {error && <p className="error">{error}</p>}
            <div className='input-box'>
              <span className='icon'>{tvIcon}</span>
              <input type='text' id='title' name='title' required />
              <label htmlFor='title'>Title</label>
            </div>
            <div className='input-box'>
              <span className='icon'>{yearIcon}</span>
              <input type='number' id='releaseYear' name='releaseYear' required />
              <label htmlFor='password'>Release year</label>
            </div>
            <div className='input-box'>
              <span className='icon'>{linkIcon}</span>
              <input type='text' id='pictureLink' name='pictureLink' required />
              <label htmlFor='pictureLink'>Picture Link</label>
            </div>
            <div className='register-link'>
              <button type='submit' value='submit'>ADD</button>
            </div>
          </form>
        </div>
      </div>
    );
}

export default AnimeAdd