
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MultiActionAreaCard from "./card";
import { Grid, TextField } from '@mui/material';
import { returnAnimeListAPI } from "../../helper/helper";
import CircularProgress from "@mui/material/CircularProgress";

function AnimeSelect() {
  const [animeList, setAnimeList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAnimeList = async () => {
      try {
        const response = await returnAnimeListAPI();
        setAnimeList(response.data);
        setIsFetching(false); // Data fetched, set isFetching to false
      } catch (err) {
        console.error('Error fetching anime list:', err);
      }
    };

    fetchAnimeList();
  }, []);

  const handleCardClick = (animeId) => {
    // console.log(typeof(animeList));
    localStorage.setItem("animeId", animeId);
    // console.log(localStorage.getItem("animeId"));
    // navigate(`/Poll/${animeId}`);
    navigate(`/Poll/${animeId}`);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredAnimeList = animeList.filter((anime) =>
    anime.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      {isFetching ? (
        <div style={{ overflow: "hidden", marginTop: "15%", justifyContent: "center", display: "flex" }}>
          <CircularProgress color="inherit" /> <br />Loading
        </div>) : (

        <Grid container padding={5} spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Search Anime"
              value={searchQuery}
              onChange={handleSearchChange}
              fullWidth
              margin="normal"
              InputProps={{
                style: { backgroundColor: 'rgba(255,255,255, 0.5)', color: 'black' },
              }}
              InputLabelProps={{
                style: { fontWeight: 'bold' },
                TypographyProps: { fontWeight: 'bold' },
              }}
            />
          </Grid>


          {filteredAnimeList.map((anime) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={anime._id} >
              <div onClick={() => handleCardClick(anime._id)}>
                <MultiActionAreaCard
                  component="img"
                  height="150"
                  image={anime.pictureLink}
                  alt={anime.title + " image"}
                  name={anime.title}
                />
              </div>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}

export default AnimeSelect;
