import * as React from 'react';
import {useState} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea} from '@mui/material';


export default function MultiActionAreaCard(props) {
  const [number, setNumber] = useState(null);
  const [isSelected, setIsSelected] = useState(false);

  const handleClick = () => {
    if (number) {
      if (props.onRemoveNumber) {
        props.onRemoveNumber(props.characterId);
      }
      setNumber(null);
    } else {
      const newNumber = props.onAssignNumber(props.characterId);
      setNumber(newNumber);
    }
    setIsSelected(!isSelected);
  };

  return (
    <Card sx={{ maxWidth: 250,  }}>
      <CardActionArea onClick={handleClick}>
        <CardMedia component={props.component} height={props.height} image={props.image} alt={props.alt} />
        <CardContent sx={{backgroundColor: isSelected ? 'black' : 'paleshadow',height:'6rem',display:'flex',justifyContent:"center",alignItems:'center',alignContent:'center'}}>
          <Typography gutterBottom variant="h6" component="div" sx={{textAlign:'center', color: isSelected? 'gold' : 'black' }}>
          <p>{props.name}</p>
          </Typography>
          {number && (
            <Typography variant="h6" color="black">
              Number: {number}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
