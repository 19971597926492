import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { Link} from 'react-router-dom';
import { BiMenuAltRight } from "react-icons/bi"
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useRef } from "react";
import logo from "../../img/logo.png"
import "./style.css"

const Footer = () => {
  return (
    <section className='f-wrapper'>
    
        <div className='flexCenter innerWidth f-container '>
   
                
        <p>For Donations and inquiries contact us at</p>    
        <div className='mail'>
        <a href="mailto:animepollrank@gmail.com"> animepollrank@gmail.com </a>
        </div> 
                        
                

                
        </div>
    </section>
  )
}

export default Footer