import React, { useEffect, useState } from "react";
import { LoginAPI, UserDataAPI } from "../../helper/helper";
import { toast, ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import UserDetails from "./UserDetails";
import Header from "../Header/Header";
import 'react-toastify/dist/ReactToastify.css';
import "./authForm.css"

const passwordIcon = <FontAwesomeIcon icon={faLock} />;
const userIcon = <FontAwesomeIcon icon={faUser} />;

const Login = () => {
  const [isLogin, setIsLogin] = useState(false);


  const handleLogin = async (event) => {
    event.preventDefault();

    const username = event.target.username.value.toLowerCase();
    const password = event.target.password.value;

    try {
      const response =await LoginAPI(username, password);
    
      if (response.status===200) {
        setIsLogin(true);
        
      } else if (response.response.status===404) {
        toast.error('Not Found - User does not exist');
      }else if(response.response.status===401){
        toast.error('Unauthorized - Incorrect password' );
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (isLogin) {
    
    return <UserDetails />;
  }
  return (
    <section>
      <head> <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1004746759020177"
        crossorigin="anonymous"></script></head>
        <Header/>
      <div className='flexCenter paddings innerWidth'>
        <div className="login-body">
        <ToastContainer />
          <div className="login-box">
            <form onSubmit={handleLogin} method="POST">
              <h2>Login</h2>
              <div className="input-box">
                <span className="icon">{userIcon}</span>
                <input type="text" id="username" name="username" required />
                <label>Username</label>
              </div>
              <div className="input-box">
                <span className="icon">{passwordIcon}</span>
                <input type="password" id="password" name="password" required />
                <label>Password</label>
              </div>

              <button type="submit">Login</button>
              <div className="register-link">
                <p>
                  Don't have an account? <Link to='/sign-up'> <a>Register</a></Link>
                </p>
              </div>
            </form>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Login