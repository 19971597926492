import React, { useState, useEffect } from "react";
import Header from './Header/Header'
import AnimeSelect from './AnimeSelect/AnimeSelect'
import Footer from "./Footer/Footer";

const Home = () => {

  return (
    <section>
      <head> 
    
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1004746759020177"
        crossorigin="anonymous"></script></head>
      <Header />
      <div className='flexCenter paddings innerWidth'>
        <AnimeSelect />
      </div>
      <Footer/>
    </section>



  )
}

export default Home