
import axios from 'axios';


// axios.defaults.baseURL = "http://localhost:3001";
axios.defaults.baseURL = "https://animepollbackend.onrender.com";


export const RegisterAPI = async (username, password, email, country) => {
  try {
    const response = await axios.post('/api/register', {
      username,
      password,
      email,
      country,
    });
  
  return response
   
   
  } catch (error) {
    // console.log(error.response.status);
    return error
  }
};



export const LoginAPI=async(username,password)=>{
  try {
    const response = await axios.post('/api/login', {
      username,
      password,

    });
    const { token, user } = response.data;

    localStorage.setItem("token",token);
    localStorage.setItem("user",JSON.stringify(user));
    return response
    
  } catch (error) {
    console.error(error);
    return error
  }
}

export const returnCharacterListAPI=async(animeId)=>{
  try{
    const response=await axios.get(`/api/getAnimeCharacter/${animeId}`);
    if (response.status !== 200) {
      throw new Error("Error fetching user data: " + response.statusText);
    }
    return response;
  }catch (error) {
    throw new Error("Error fetching user data: " + error.message);
  }

}





export const returnAnimeListAPI=async()=>{
  try{
    const response=await axios.get("/api/animeList");
    if (response.status !== 200) {
      throw new Error("Error fetching user data: " + response.statusText);
    }
    return response;
  }catch (error) {
    throw new Error("Error fetching user data: " + error.message);
  }

}

// export const UserDataAPI = async (token) => {

//   try {
//     const response = await axios.post("/api/userData", {token});

//     if (response.status !== 200) {
//       throw new Error("Error fetching user data: " + response.statusText);
//     }

//     return response.data;
//   } catch (error) {
//     throw new Error("Error fetching user data: " + error.message);
//   }
// };


export const addAnimeAPI = async (title, releaseYear, pictureLink) => {
    try {
      const response = await axios.post(`/api/addanime`, {
        title,
        releaseYear,
        pictureLink,
      });
  
      const {status}=response.data
      localStorage.setItem('status',status)
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  export const addAnimeCharacterAPI = async (name, anime, pictureLink) => {
    try {
      const response = await axios.post(`/api/addAnimeCharacter`, {
        name,
        anime,
        pictureLink,
      });
      const {status}=response.data
      localStorage.setItem('status',status)
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  export const addRankScoresAPI = async (charactersWithNumbers) => {
    try {
      const response = await axios.post(`/api/rankScores`, {
        charactersWithNumbers
      });
   
      return response.data;
      
    } catch (error) {
      console.error(error);

      throw error
    
    }
  };

  export const addRankScoresAPIFav = async (charactersWithNumbers) => {
    try {
      const response = await axios.post(`/api/rankScoresFav`, {
        charactersWithNumbers
      });
  
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };


  
  export const returnRankListAPI=async(animeId)=>{
    try{
      const response=await axios.get(`api/rankScores/${animeId}`);
      if (response.status !== 200) {
        throw new Error("Error fetching user data: " + response.statusText);
      }
      return response;
    }catch (error) {
      throw new Error("Error fetching user data: " + error.message);
    }
  
  }

  export const returnRankListAPIFav=async(animeId)=>{
    try{
      const response=await axios.get(`api/rankScoresFav/${animeId}`);
      if (response.status !== 200) {
        throw new Error("Error fetching user data: " + response.statusText);
      }
      return response;
    }catch (error) {
      throw new Error("Error fetching user data: " + error.message);
    }
  
  }
  

  export const LogoutAPI=async()=>{

  }

